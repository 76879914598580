import React from "react"
import { Link, graphql } from "gatsby"
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"
import moment from 'moment'
import urlSlug from 'url-slug'

import { color, font, breakpoint } from '../components/styles'

const CareersContainer = styled.div`
  .page-header {
    text-align: center;
    font-size: ${font.size.xxlarge};
    border-bottom: 1px solid #ccc;
    padding: 0 0 2.5%;
    margin: 2% auto 0;
    width: calc(100% - 20px);
    max-width: 1200px;
    @media screen and (max-width: ${breakpoint.xsmall}) {
      font-size: ${font.size.xlarge};
    }
  }

  .page-info {
    border-bottom: 1px solid #ccc;
    padding: 0 0 2.5%;
    margin: 2% auto 0;
    width: calc(100% - 20px);
    max-width: 1200px;

    h2 {
      text-align: center;
    }

    .perks {
      max-width: 1000px;
      margin: 5% auto 0;
      > p {
        margin-left: 20px;
        display: list-item;
        list-style: disc;
      }
    }
  }

  .posts {
    margin: 2.5% auto;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 1000px;
    a {
      padding: 12px;
      margin: 0 auto;
      width: 100%;
      height: auto;
      color: ${color.text.default};
      text-decoration: none;
      border-bottom: 1px solid #ccc;
      &:hover {
        color: ${color.text.black};
        text-decoration: underline;
      }
    }
    h2 {
      font-size: ${font.size.xlarge};
      border-bottom: 1px solid #ccc;
      padding: 0 20px 20px;
      margin: 0 auto 40px;
    }

    .title-wrap {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      h3 {
        margin: 0 20px 0 0
      }
      span {
        margin-left: auto;
      }
      @media screen and (max-width: ${breakpoint.small}) {
        flex-flow: column nowrap;
        align-items: start;
        span {
          margin-left: 0;
        }
      }
    }
  }
`

const CareersPage = ({ data }) => {
  console.log(data.allMarkdownRemark.edges)
  return (
    <Layout>
      <CareersContainer>
        <SEO title="Careers" />
        <h1 className='page-header'>Careers at D6 Inc.</h1>
        <div className='page-info'>
          <h2>Joining D6 Inc. comes with perks to support you in your personal and professional journey.</h2>
          <div className="perks">
            <h3>How we elevate you:</h3>
            <p>Teamwork: experience what being a part of the D6 family feels like. Be inspired by your leaders, be encouraged and cheered on by your teammates to live a big life, and be supported in your whole life working while you work with us.</p>
            <p>Learning + Development: take on leadership development programs and goal setting to create big possibilities for your career and life. Develop within the company; be mentored and grown as a leader from the minute you’re hired.</p>
            <p>Full benefits, 401k, 401k matching, Employee assistance program.</p>
          </div>
        </div>
        
        <div className='posts'>
          { (data.allMarkdownRemark.edges && data.allMarkdownRemark.edges.length > 0) ?
            <React.Fragment>
              <h2>Open Positions</h2>
              {data.allMarkdownRemark.edges.map(document => {
                console.log(document)
                if (document.node.html && document.node.html.length > 0) {
                  return (
                    <Link to={`/career/${moment(document.node.frontmatter.date).format('YYYY/MM')}/${urlSlug(document.node.frontmatter.title)}`} key={document.node.id}>
                      <div className='title-wrap'>
                        <h3>{document.node.frontmatter.title}</h3>
                        <h4>{document.node.frontmatter.location}</h4>
                        <span>Learn More</span>
                      </div>
                    </Link>
                  )
                } else {
                  return (
                    <a href={`${document.node.frontmatter.link}`} key={document.node.id}>
                      <div className='title-wrap'>
                        <h3>{document.node.frontmatter.title}</h3>
                        <h4>{document.node.frontmatter.location}</h4>
                        <span>Apply Now</span>
                      </div>
                    </a>
                  )
                }
               
              })}
            </React.Fragment>
            :
            <h2>Sorry, it looks like we currently don't have any positions open.</h2>
          }
        </div>
      </CareersContainer>
    </Layout>
  )
}

export default CareersPage

export const postQuery = graphql`
  query CareersPage {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { templateKey: { eq: "career" } } } 
    ){
      edges {
        node {
          id
          html
          frontmatter {
            title
            location
            date
            link
          }
        }
      }
    }
  }
`